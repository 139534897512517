.main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.container {
    height: 100%;
    width: 60%;
    padding: 30px;
}

@media only screen and (max-width: 460px) {
    .container {
        width: calc(100% - 40px);
        padding: 20px;
    }
}