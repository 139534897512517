
.glass {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    height: 90%;
    background: linear-gradient(126.6deg, rgba(255, 255, 255, 0.12) 28.69%, rgba(255, 255, 255, 0) 100%);
    border-radius: 25px;
    border: 1px solid rgba(56, 84, 231, 0.2);
    margin: 20px;
}

@media only screen and (max-width: 460px) {
    .glass {
        width: 100%;
        background: none;
        border: none;
        backdrop-filter: none;
    }
}